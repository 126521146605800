var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseModal',{ref:"modal",attrs:{"title":_vm.title,"max-width":"tw-max-w-6xl"}},[_c('DataTable',_vm._b({attrs:{"loading":_vm.loading,"headers":_vm.headers,"can-select":true,"infinite-scroll":true,"max-select-all-amount":5000,"all-record-ids":_vm.allHmodhPropertyIds},on:{"infiniteLoad":_vm.infiniteLoad},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('div',{staticClass:"tw-mb-2 tw-flex tw-justify-end"},[_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"action tw-bg-primary",attrs:{"disabled":_vm.selectedRecords.length === 0,"data-toggle":"dropdown"}},[(!_vm.emailDataLoading)?_c('span',[_vm._v(" Nieuwe activiteit "),_c('i',{staticClass:"fas fa-caret-down"})]):_c('span',[_c('i',{staticClass:"fa fa-spin fa-spinner"}),_vm._v(" Laden ... ")])]),_c('ul',{staticClass:"dropdown-menu"},[_c('li',[_c('a',{attrs:{"title":"Telefoon"},on:{"click":function($event){return _vm.showFeedbackModal('phone')}}},[_vm._v(" Telefoon ")])]),_c('li',[_c('a',{attrs:{"title":"Email"},on:{"click":_vm.showMailModal}},[_vm._v(" E-mail ")])]),_c('li',[_c('a',{attrs:{"title":"Gesprek"},on:{"click":function($event){return _vm.showFeedbackModal('conversation')}}},[_vm._v(" Gesprek ")])])])]),_c('button',{staticClass:"action tw-bg-warning",attrs:{"disabled":!_vm.selectedRecords.length},on:{"click":function($event){return _vm.showFeedbackModal('break')}}},[_c('i',{staticClass:"fas fa-times"}),_vm._v(" Geen interesse ")])])]},proxy:true},{key:"item-property_reference",fn:function(ref){
var item = ref.item;
return [_c('EntityHoverable',{attrs:{"value":{ id: item.property.id, reference: item.property.reference, type: 'property' }},on:{"clicked":_vm.hide}})]}},{key:"item-contact_name",fn:function(ref){
var item = ref.item;
return [(item.hmodh && item.hmodh.contact)?_c('router-link',{attrs:{"to":{ name: 'ContactDetails', params: { id: item.hmodh.contact.id } }},nativeOn:{"click":function($event){return _vm.hide.apply(null, arguments)}}},[_vm._v(" "+_vm._s(item.hmodh.contact.display_name)+" ")]):_vm._e()]}},{key:"item-details",fn:function(ref){
var item = ref.item;
return [(item.hmodh)?_c('span',{class:['label', item.hmodh.manual ? 'label-warning' : 'label-primary']},[_vm._v(" "+_vm._s(item.hmodh.manual ? 'Manuele HMODH' : 'Automatisch HMODH')+" ")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm._f("date-day")(item.updated_on)))])]}},{key:"item-adres",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.property.street)+" "+_vm._s(item.property.number)+" "+_vm._s(item.property.box)+", "+_vm._s(item.property.city.zip_code)+" "+_vm._s(item.property.city.name)+" ")]}}]),model:{value:(_vm.selectedRecords),callback:function ($$v) {_vm.selectedRecords=$$v},expression:"selectedRecords"}},'DataTable',_vm.hmodhProperties,false))],1),_c('BaseModal',{ref:"feedbackModal",attrs:{"title":_vm.feedbackTitle}},[_c('FormulateForm',{on:{"submit":_vm.finishActivity},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('FormulateInput',{attrs:{"type":"textarea","label":"Feedback rapportering","input-class":['tw-h-24']},model:{value:(_vm.feedbackReporting),callback:function ($$v) {_vm.feedbackReporting=$$v},expression:"feedbackReporting"}}),_c('FormulateInput',{attrs:{"type":"textarea","label":"Interne feedback","input-class":['tw-h-24']},model:{value:(_vm.feedback),callback:function ($$v) {_vm.feedback=$$v},expression:"feedback"}}),_c('div',{staticClass:"tw-flex tw-justify-end"},[_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading,"outer-class":"tw-mt-2"}},[_c('i',{class:[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
            ]}),_vm._v(" Opslaan ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }