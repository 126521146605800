<template>
  <PanelBox title="Contacten">
    <span slot="toolbar">
      <button
        type="button"
        class="toolbar-button"
        @click="createEntityContact"
      >
        <i class="fas fa-plus" /> Aanmaken
      </button>
    </span>
    <DataTable
      :headers="HEADERS"
      :infinite-scroll="true"
      :show-result-count="false"
      :infinite-key="identifier"
      v-bind="contacts"
      class="tw--m-5 tw-shadow-none"
      @infiniteLoad="infiniteLoad"
    >
      <template #item-contact="{ item }">
        <EntityContact :contact="item.contact" />
      </template>
      <template #item-role="{ item }">
        <span>{{ item.role.name }}</span>
      </template>
      <template #item-actions="{ item }">
        <button
          title="Contact bewerken"
          type="button"
          class="action tw-bg-success"
          @click="updateEntityContact(item)"
        >
          <i class="far fa-pencil"/>
        </button>
        <button
          title="Communicatie verwijderen"
          type="button"
          class="action tw-bg-danger"
          @click="deleteEntityContact(item)"
        >
          <i class="far fa-trash-alt"/>
        </button>
      </template>
    </DataTable>
    <EntityContactsCreateUpdateModal
      ref="entityContactsCreateUpdateModal"
      @created="refreshEntityContacts"
      @updated="refreshEntityContacts"
    />
  </PanelBox>
</template>

<script>
import uniqueId from 'lodash/uniqueId'

import PanelBox from '@/components/iam/PanelBox'
import DataTable from '@/components/DataTable'
import EntityContact from '@/components/properties/EntityContact.vue'
import EntityContactsCreateUpdateModal from '@/components/properties/EntityContactsCreateUpdateModal.vue'
import { successModal, errorModal, questionModal } from '@/modalMessages'
import { getPropertyContacts, deletePropertyContact } from '@/services/properties'
import { getProjectContacts, deleteProjectContact } from '@/services/projects'

export default {
  name: 'EntityContacts',
  components: {
    EntityContact,
    EntityContactsCreateUpdateModal,
    DataTable,
    PanelBox
  },
  props: {
    propertyId: {
      type: [String, Number]
    },
    projectId: {
      type: [String, Number]
    }
  },
  constants: {
    HEADERS: [
      { text: 'Contact', value: 'contact' },
      { text: 'Rol', value: 'role' },
      { text: 'Acties', value: 'actions' }
    ]
  },
  data () {
    return {
      contacts: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      identifier: uniqueId('contacts_')
    }
  },
  methods: {
    async loadEntityContacts (payload) {
      try {
        const response = this.propertyId
          ? await getPropertyContacts(payload)
          : await getProjectContacts(payload)
        return response.data
      } catch (error) {
        console.error(error)
      }
    },
    async infiniteLoad ($infinite, next) {
      try {
        const payload = {
          ...this.propertyId
            ? { propertyId: this.propertyId }
            : { projectId: this.projectId }
        }
        if (next) payload.url = next
        const contacts = await this.loadEntityContacts(payload)
        const results = [...this.contacts.results, ...contacts.results]
        this.contacts = { ...contacts, results }
        if (results.length) $infinite.loaded()
        if (!contacts.next) $infinite.complete()
        return contacts
      } catch (error) {
        $infinite.error()
        console.error(error)
      }
    },
    async refreshEntityContacts () {
      this.contacts = {
        count: null,
        next: null,
        previous: null,
        results: []
      }
      this.identifier = uniqueId('refreshed_contacts_')
    },
    createEntityContact () {
      const dummyEntityContact = this.propertyId
        ? { property: this.propertyId }
        : { project: this.projectId }
      this.$refs.entityContactsCreateUpdateModal.show(dummyEntityContact, 'create')
    },
    updateEntityContact (entityContact) {
      this.$refs.entityContactsCreateUpdateModal.show(entityContact, 'update')
    },
    async deleteEntityContact (entityContact) {
      const result = await questionModal('Ben je zeker dat je dit contact wilt verwijderen?')
      if (result.value) {
        try {
          const response = 'property' in entityContact
            ? await deletePropertyContact(entityContact.property, entityContact.id)
            : await deleteProjectContact(entityContact.project, entityContact.id)
          successModal('Het contact werd verwijderd.')
          await this.refreshEntityContacts()
          return response
        } catch (error) {
          errorModal('Er is een fout opgetreden bij het verwijderen van het contact. De reden hiervoor kan zijn dat dit contact gelinkt is aan een factuur voor dit pand.')
          console.error(error)
        }
      }
    }
  }
}
</script>
