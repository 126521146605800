<template>
  <div>
    <BaseModal ref='modal' title="Opschortende voorwaarde">
      <FormulateForm
        v-model="values"
        name="suspensiveConditionForm"
        @submit="submit"
      >
        <FormulateInput
          name="ovab_type"
          label="Type"
          type="select"
          placeholder="Kies een type"
          :options="ovabTypes"
          :validation="'bail|required'"
          :validation-messages="{
            required: 'Type is verplicht.',
          }"
          outer-class="tw-m-0 tw-w-1/2"
        />
        <FormulateInput
          type="date"
          name="ovab_date"
          label="Opvolgdatum"
          placeholder="YYYY-MM-DD"
          :show-required-label="true"
          validation="bail|required|date:YYYY-MM-DD"
        />
        <FormulateInput
          type="date"
          name="ovab_due_date"
          label="Vervaldatum"
          placeholder="YYYY-MM-DD"
          :show-required-label="true"
          validation="bail|required|date:YYYY-MM-DD"
        />
        <FormulateInput
          type="textarea"
          name="ovab_information"
          label="Opmerkingen"
          placeholder="Opmerkingen"
        />
        <FormulateInput
          type="submit"
          title="Afpunten"
          outer-class="tw-my-4 tw-float-right"
        >
          Opslaan
        </FormulateInput>
        <FormulateInput
          type="button"
          title="OV niet voldaan"
          outer-class="tw-m-4 tw-float-right"
          @click="cancelSuspensiveCondition"
        >
          OV niet voldaan
        </FormulateInput>
        <FormulateInput
          type="button"
          title="OV voldaan"
          outer-class="tw-my-4 tw-float-right"
          @click="finishSuspensiveCondition"
        >
          OV voldaan
        </FormulateInput>
      </FormulateForm>
    </BaseModal>
    <PropertyCancelDossierModal
      ref="propertyCancelDossierModal"
      :property-id="propertyId"
      @dossier-cancelled="handleDossierCancelled"
    />
  </div>
</template>

<script>
import PropertyCancelDossierModal from '@/components/properties/PropertyCancelDossierModal'

import { successModal } from '@/modalMessages'
import { getSuspensiveConditions } from '@/services/apiService'
import { finishSuspensiveCondition, updateTransaction } from '@/services/transactions'

export default {
  name: 'SuspensiveConditionModal',
  components: {
    PropertyCancelDossierModal
  },
  props: {
    propertyId: {
      type: [String, Number]
    }
  },
  data () {
    return {
      transaction: {},
      values: {},
      ovabTypes: []
    }
  },
  methods: {
    async show () {
      await this.fetchOvabTypes()
      const { ovab_type, ovab_date, ovab_due_date, ovab_information } = this.transaction
      this.values = {
        ovab_type: ovab_type,
        ovab_date: ovab_date,
        ovab_due_date: ovab_due_date,
        ovab_information: ovab_information
      }
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    handleDossierCancelled () {
      this.hide()
      this.$emit('suspensiveConditionCancelled')
    },
    async submit (data) {
      try {
        const response = await updateTransaction(this.transaction.id, { ...this.values })
        this.hide()
        successModal('De opschortende voorwaarde werd bewerkt.')
        this.$emit('suspensiveConditionEdited')
        return response
      } catch (error) {
        this.$formulate.handle(error, 'suspensiveConditionForm')
      }
    },
    async finishSuspensiveCondition () {
      try {
        const response = await finishSuspensiveCondition(this.transaction.id)
        this.hide()
        successModal('De opschortende voorwaarde werd gemarkeerd als voldaan')
        this.$emit('suspensiveConditionFinished')
        return response
      } catch (error) {
        this.$formulate.handle(error, 'suspensiveConditionForm')
      }
    },
    async cancelSuspensiveCondition () {
      this.$refs.propertyCancelDossierModal.show()
    },
    async fetchOvabTypes () {
      const response = await getSuspensiveConditions()
      const conditions = response.data?.results || []
      this.ovabTypes = conditions.map(({ id, name }) => {
        return { label: name, value: id }
      })
      return response
    }
  }
}
</script>
