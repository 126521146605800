var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{ref:"modal",attrs:{"title":"Uit portefeuille","max-width":"tw-max-w-2xl"},on:{"hide":_vm.hide}},[_c('WizardRemovedFromPortfolioStep1',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.activeStep === 1),expression:"activeStep === 1"}],on:{"finished":_vm.moveForward}},'WizardRemovedFromPortfolioStep1',{
      property: _vm.property,
      propertyId: _vm.propertyId,
      terminationReasons: _vm.terminationReasons,
    },false)),_c('WizardRemovedFromPortfolioStep2',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.activeStep === 2),expression:"activeStep === 2"}]},'WizardRemovedFromPortfolioStep2',{
      property: _vm.property,
      propertyId: _vm.propertyId,
      isMandateEndDateInThePast: _vm.isMandateEndDateInThePast
    },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }