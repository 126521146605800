<template>
  <div v-if="hasOpenTodos">
    <div v-for="(category, index) in checklist" :key="index">
      <router-link
        :to="{ name: 'PropertyChecklist' }"
        class="tw-block tw-font-bold tw-my-4 link"
        target="_blank"
      >
        {{ category.name }}
      </router-link>
      <div class="tw-flex tw-flex-wrap tw-gap-2">
        <span v-for="todo in category.todos.to_do" :key="todo.id" class="tw-border tw-px-2">
          {{ todo.title }}
        </span>
        <span v-for="todo in category.todos.in_progress" :key="todo.id" class="tw-border tw-px-2">
          {{ todo.title }}
        </span>
      </div>
    </div>
  </div>
  <div v-else>
    Er zijn geen openstaande checklistitems.
  </div>
</template>

<script>
import { getPropertyChecklistV2 } from '@/services/checklists'

export default {
  name: 'WizardChecklistItems',
  props: {
    propertyId: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      checklist: null
    }
  },
  computed: {
    hasOpenTodos () {
      let result = false
      if (!this.checklist) return false
      this.checklist.some(category => {
        if (category.todos.to_do.length) {
          result = true
          return true
        }
        return false
      })
      return result
    }
  },
  created () {
    this.fetchChecklist()
  },
  methods: {
    async fetchChecklist () {
      const params = { exclude_not_applicable: 1 }
      const response = await getPropertyChecklistV2(this.propertyId, params)
      this.checklist = response.data
      return response
    }
  }
}
</script>
