<template>
  <BaseModal
    ref="modal"
    title="Facturatie openstaande kosten"
    max-width="tw-max-w-2xl"
    @hide="hide"
  >
    <div v-if="!costs.length">
      Er zijn geen openstaande kosten.
    </div>
    <div v-else>
      <button
        v-show="showPreview && !invoicingDone"
        @click="goBack"
        class="btn btn-default tw-text-xs tw-mb-4"
      >
        <i class="fas fa-arrow-left tw-mr-1" /> Terug naar kostenoverzicht
      </button>
      <FormulateForm
        v-show="showCosts"
        #default="{ isLoading }"
        v-model="values"
        name="invoiceForm"
        :form-errors="[noCostSelected]"
        class="tw-max-w-2xl"
        @submit="submit"
      >
        <h2>Facturatiecontacten</h2>
        <div class="tw-mb-8">
          <div v-if="invoiceContacts.length" class="tw-flex tw-gap-4 tw-flex-wrap">
              <div
                v-for="(invoiceContact, index) in invoiceContacts"
                :key="`invoiceContact_${index}`"
                class="tw-border tw-p-2 tw-rounded"
              >
                <EntityInvoiceContactsDisplay :invoice-contact="invoiceContact" :show-role-label="true" />
              </div>
          </div>
          <div v-else class="tw-italic">
            Er zijn openstaande kosten, maar er zijn geen facturatiecontacten ingesteld.
            Gelieve dit scherm te sluiten en de facturatiecontacten in te geven via het tabblad 'Algemeen'.
            Daarna kun je via het tabblad 'Kosten/facturatie' alsnog de kosten factureren.
          </div>
        </div>

        <h2>Kosten</h2>
        <template v-for="cost in costs">
          <div
            :key="cost.id"
            :title="cost.description"
            class="tw-my-4 tw-flex tw-flex-row tw-gap-4 tw-items-start"
          >
            <FormulateInput
              v-model="values.cost_ids"
              :id="`cost_${cost.id}`"
              type="checkbox"
              name="cost_ids"
              :value="cost.id"
              outer-class="tw-my-0"
            />
            <label
              :for="`cost_${cost.id}`"
              class="formulate-label tw-font-normal tw-flex tw-flex-row tw-justify-between tw-gap-4 tw-w-full"
            >
              <div>
                <h4 v-if="cost.product" class="tw-my-0">
                  {{ cost.product.supplier.name }}
                  -
                  {{ cost.product.product.name }}
                </h4>
                <span class="tw-text-xs">
                  {{ cost.description }}
                </span>
              </div>
              <span>
                {{ currency(cost.amount) }} excl. btw
              </span>
            </label>
          </div>
        </template>

        <FormulateErrors class="tw-text-right" />

        <div class="tw-flex tw-justify-end">
          <FormulateInput
            v-if="costs"
            type="submit"
            :disabled="isLoading || !invoiceContacts.length"
            outer-class="tw-mt-2"
          >
            <i  class="fas fa-eye tw-mr-1"
              :class="[
                'fas tw-mr-1',
                isLoading ? 'fa-spinner-third fa-spin' : 'fa-eye'
              ]"
            />
            Factuurvoorbeeld
          </FormulateInput>
        </div>
      </FormulateForm>
      <EntityInvoicePreview
        v-show="showPreview"
        ref="entityInvoicePreview"
        :entity-type="entityType"
        :entity-id="entityId"
        @invoicing-done="setInvoicingDone"
      />
    </div>
  </BaseModal>
</template>

<script>
import EntityInvoicePreview from '@/components/properties/EntityInvoicePreview'
import EntityInvoiceContactsDisplay from '@/components/properties/EntityInvoiceContactsDisplay'

import { getCosts } from '@/services/costs'
import { getProjectInvoiceContacts } from '@/services/projects'
import { getPropertyInvoiceContacts } from '@/services/properties'

import { currency, percentage, getContactAddress } from '@/utils/helpers'

export default {
  name: 'WizardEntityInvoice',
  components: {
    EntityInvoicePreview,
    EntityInvoiceContactsDisplay
  },
  data () {
    return {
      invoiceContacts: [],
      costs: [],
      values: {
        cost_ids: []
      },
      meta: {},
      formError: null,
      showCosts: true,
      showPreview: false,
      invoicingDone: false,
      showNoCostsMessage: false
    }
  },
  computed: {
    entityType () {
      return this.$route.meta.entity_type
    },
    entityId () {
      return this.$route.params.id
    },
    noCostSelected () {
      if (this.values.cost_ids.length < 1) return 'Er moet ten minste één kost zijn geselecteerd.'
      else return null
    }
  },
  methods: {
    currency,
    percentage,
    show () {
      this.$refs.modal.show()
    },
    getInvoiceContactPrimary (invoiceContact) {
      return invoiceContact.contacts.find(function (contact) { return contact.primary })?.contact
    },
    getInvoiceContactAddress (invoiceContact) {
      const primaryContact = this.getInvoiceContactPrimary(invoiceContact)
      const address = getContactAddress(primaryContact)
      return address
    },
    goBack () {
      this.showCosts = true
      this.showPreview = false
    },
    setInvoicingDone () {
      this.invoicingDone = true
    },
    hide () {
      this.$emit('wizardEntityInvoiceHidden')
    },
    async init () {
      const promises = await Promise.all([
        this.loadInvoiceContacts(),
        this.fetchEntityCosts()
      ])
      this.show()
      if (!this.costs.length) {
        setTimeout(() => {
          this.$refs.modal.hide()
        }, 5000)
      }
      return promises
    },
    async fetchEntityCosts () {
      const params = {
        invoice_moment: 2, // Invoiced at the moment of publication
        is_invoiceable: true, // Fetch costs that are invoiceable
        is_settled: false, // Fetch costs that have not been settled yet
        page_size: 100, // Let's fetch all the costs at once, if they are too many, we will add pagination later.
        [this.entityType]: this.entityId
      }

      const response = await getCosts({ params })
      this.costs = response.data?.results.map(cost => {
        this.values.cost_ids.push(cost.id)
        return cost
      })

      return response
    },
    async loadInvoiceContacts () {
      const response = this.entityType === 'property'
        ? await getPropertyInvoiceContacts(this.entityId)
        : await getProjectInvoiceContacts(this.entityId)
      this.invoiceContacts = response.data
      return response
    },
    async submit (data) {
      try {
        const { cost_ids } = data
        if (this.noCostSelected) return false
        const response = await this.$refs.entityInvoicePreview.init({ cost_ids })
        this.showCosts = false
        this.showPreview = true
        return response
      } catch (error) {
        console.error(error)
        this.$formulate.handle(error, 'invoiceForm')
      }
    }
  }
}
</script>
