<template>
  <div>
    <BaseModal ref="modal" :title="title" max-width="tw-max-w-6xl">
      <DataTable
        v-model="selectedRecords"
        :loading="loading"
        :headers="headers"
        :can-select="true"
        :infinite-scroll="true"
        :max-select-all-amount="5000"
        :all-record-ids="allHmodhPropertyIds"
        v-bind="hmodhProperties"
        @infiniteLoad="infiniteLoad"
      >
        <template #toolbar>
          <div class="tw-mb-2 tw-flex tw-justify-end">
            <div class="btn-group">
              <button
                :disabled="selectedRecords.length === 0"
                data-toggle="dropdown"
                class="action tw-bg-primary"
              >
                <span v-if="!emailDataLoading">
                  Nieuwe activiteit <i class="fas fa-caret-down" />
                </span>
                <span v-else>
                  <i class="fa fa-spin fa-spinner" /> Laden ...
                </span>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a title="Telefoon" @click="showFeedbackModal('phone')">
                    Telefoon
                  </a>
                </li>
                <li>
                  <a title="Email" @click="showMailModal">
                    E-mail
                  </a>
                </li>
                <li>
                  <a title="Gesprek" @click="showFeedbackModal('conversation')">
                    Gesprek
                  </a>
                </li>
              </ul>
            </div>
            <button
              :disabled="!selectedRecords.length"
              class="action tw-bg-warning"
              @click="showFeedbackModal('break')"
            >
              <i class="fas fa-times" /> Geen interesse
            </button>
          </div>
        </template>

        <template #item-property_reference="{ item }">
          <EntityHoverable
            :value="{ id: item.property.id, reference: item.property.reference, type: 'property' }"
            @clicked="hide"
          />
        </template>

        <template #item-contact_name="{ item }">
          <router-link
            v-if="item.hmodh && item.hmodh.contact"
            :to="{ name: 'ContactDetails', params: { id: item.hmodh.contact.id } }"
            @click.native="hide"
          >
            {{ item.hmodh.contact.display_name }}
          </router-link>
        </template>

        <template #item-details="{ item }">
          <span v-if="item.hmodh" :class="['label', item.hmodh.manual ? 'label-warning' : 'label-primary']">
            {{ item.hmodh.manual ? 'Manuele HMODH' : 'Automatisch HMODH' }}
          </span>
          <span>{{ item.updated_on | date-day }}</span>
        </template>

        <template #item-adres="{ item }">
          {{ item.property.street }} {{item.property.number}} {{ item.property.box }}, {{ item.property.city.zip_code }} {{ item.property.city.name }}
        </template>
      </DataTable>
    </BaseModal>
    <BaseModal
      ref="feedbackModal"
      :title="feedbackTitle"
    >
      <FormulateForm
        #default="{ isLoading }"
        @submit="finishActivity"
      >
        <FormulateInput type="textarea" v-model="feedbackReporting" label="Feedback rapportering" :input-class="['tw-h-24']" />
        <FormulateInput type="textarea" v-model="feedback" label="Interne feedback" :input-class="['tw-h-24']" />
        <div class="tw-flex tw-justify-end">
          <FormulateInput
            type="submit"
            :disabled="isLoading"
            outer-class="tw-mt-2"
          >
            <i
              :class="[
                'fas tw-mr-1',
                isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
              ]"
            />
            Opslaan
          </FormulateInput>
        </div>
      </FormulateForm>
    </BaseModal>
  </div>
</template>

<script>
import EventBus from '@/components/iam/bus'
import DataTable from '@/components/DataTable'
import EntityHoverable from '@/components/iam/EntityHoverable'
import HmodhCriteria from '@/components/contacts/hmodh/Criteria'

import { mapActions } from 'vuex'
import { errorModal, successModal } from '@/modalMessages'
import { getContactsByIds } from '@/services/contacts'
import { getHmodhProperties } from '@/services/hmodh'
import { getPropertyByIds } from '@/services/properties'
import { createHmodhPropertyLeads } from '@/services/sales'
import { getJobStatus } from '@/services/apiService'
import { poll } from '@/utils/helpers'

export default {
  name: 'HmodhPropertiesModal',
  props: {
    property: {
      type: Object,
      required: false
    },
    project: {
      type: Object,
      required: false
    }
  },
  components: {
    DataTable,
    EntityHoverable
  },
  data () {
    return {
      loading: false,
      // The object hmodhProperties includes keys to all the props that are needed in the DataTable, hence, we can use v-bind directly for clean code
      hmodhProperties: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      selectedRecords: [],
      allHmodhPropertyIds: [],
      hmodh: null, // Set by other components using this component
      emailDataLoading: false,
      feedbackReporting: '',
      feedback: '',
      selectedFeedbackType: ''
    }
  },
  computed: {
    title () {
      if (this.property) {
        return 'HMODH\'s in aanmerking voor dit pand'
      } else if (this.project) {
        return 'HMODH\'s in aanmerking voor dit project'
      } else {
        return 'Panden in aanmerking voor deze HMODH'
      }
    },
    headers () {
      if (this.property || this.project) {
        return [
          { value: 'contact_name', text: 'Naam' },
          { value: 'details', text: 'HMODH' },
          { value: 'hmodh.comment_internal', text: 'Extra informatie HMODH' },
          { value: 'criteria', text: 'Criteria', renderComponent: HmodhCriteria }
        ]
      }
      return [
        { value: 'property_reference', text: 'Referentie', renderComponent: 'hmodh-reference' },
        { value: 'adres', text: 'Adres' }
      ]
    },
    feedbackTitle () {
      const titles = {
        phone: 'Telefoongesprek registreren',
        conversation: 'Gesprek registreren',
        break: 'HMODH breaken'
      }
      return titles[this.selectedFeedbackType] || 'Hou me op de hoogte'
    },
    queryParams () {
      const params = { mailable: true }
      if (this.hmodh) params.hmodh = this.hmodh.id
      else if (this.property) params.property = this.property.id
      else if (this.project) params.project = this.project.id
      return params
    }
  },
  methods: {
    ...mapActions('properties', ['loadProperty']),

    showFeedbackModal (selectedFeedbackType) {
      this.selectedFeedbackType = selectedFeedbackType
      this.$refs.feedbackModal.show()
    },
    hide () {
      console.debug('hiding mailclient')
      EventBus.$off('mailclient-hidden')
      this.$refs.modal.hide()
    },

    async show () {
      try {
        this.$refs.modal.show()
        // After closing the mailclient show the hmoh properties again
        EventBus.$on('mailclient-hidden', this.show)

        this.loading = true
        const properties = await this.fetchHmodhProperties()
        this.hmodhProperties = properties
        this.loading = false
        return properties
      } catch (error) {
        errorModal('Fout bij het laden van HMODH-panden, probeer het opnieuw.')
      }
    },

    async fetchHmodhProperties (payload) {
      if (payload == null) payload = { params: this.queryParams }
      const response = await getHmodhProperties(payload)
      if (!this.allHmodhPropertyIds.length) await this.getAllHmodhPropertyIds(payload)
      return response.data
    },

    async getAllHmodhPropertyIds (payload) {
      const paramsWithIdsOnly = { params: { ...payload.params, ids_only: 1 } }
      const response = await getHmodhProperties(paramsWithIdsOnly)
      this.allHmodhPropertyIds = response.data
      return response
    },

    async infiniteLoad ($infinite) {
      try {
        const next = this.hmodhProperties.next
        if (!next) {
          // No more data and state is loaded
          if (this.hmodhProperties.results.length) $infinite.loaded()
          return $infinite.complete()
        }
        const properties = await this.fetchHmodhProperties({ url: next })
        const results = [...this.hmodhProperties.results, ...properties.results]
        this.hmodhProperties = { ...properties, results }
        $infinite.loaded()
        return properties
      } catch (error) {
        $infinite.error()
        console.error(error)
      }
    },

    async getContacts () {
      if (this.property) {
        const response = await getContactsByIds({ hmodh_property_ids: this.selectedRecords.join(',') })
        return response.data.map(contactItem => {
          return { type: 'candidate', contact: contactItem }
        })
      } else {
        return [{ type: 'candidate', contact: this.hmodhProperties?.results[0]?.hmodh?.contact }]
      }
    },
    async getProperties () {
      if (this.property) {
        return [this.hmodhProperties?.results[0]?.property]
      } else {
        const response = await getPropertyByIds({ hmodh_property_ids: this.selectedRecords.join(',') })
        return response.data
      }
    },

    async showMailModal () {
      // Don't use this.hide() to hide this modal, we need to keep the trigger removed on the EventBus
      // in the hide() method for this flow to work.
      this.emailDataLoading = true
      this.selectedFeedbackType = 'email'
      const properties = await this.getProperties()
      const contacts = await this.getContacts()
      this.emailDataLoading = false
      this.$refs.modal.hide()

      // disableCommunicationForm, disables the popup screen for the activity
      // disableMailSend disables the sending of the mail
      // callback will handle the mail output
      EventBus.$emit('mailclient-prefill-and-show', {
        contacts,
        context: { properties },
        callback: { callback: this.finishActivity }
      })
    },
    async finishActivity () {
      // Handle activity processing
      const actType = this.selectedFeedbackType
      const feedback_reporting = this.feedbackReporting
      const feedback = this.feedback

      let activity_type
      let is_break = false

      // Don't create leads when we sent an email, the mailclient will handle all logic due to lead creation
      if (actType === 'email') {
        return
      }
      if (actType === 'phone') {
        activity_type = 1
      } else if (actType === 'conversation') {
        activity_type = 14
      } else if (actType === 'break') {
        activity_type = 104
        is_break = true
      }
      const payload = {
        hmodh_property_ids: this.selectedRecords,
        activity_type,
        is_break,
        feedback,
        feedback_reporting
      }
      const response = await createHmodhPropertyLeads(payload)
      await poll(response?.data?.job_id, getJobStatus, 1000)
      this.$refs.feedbackModal.hide()
      if (is_break) await this.fetchHmodhProperties()
      this.feedback = ''
      this.feedbackReporting = ''
      const successMessage = is_break ? 'De breaks werden geregistreerd' : 'De activiteiten werden geregistreerd'
      successModal(successMessage)
      return response
    }
  }
}
</script>
