<template>
  <BaseModal ref='modal' title="Contacten/Rollen">
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      name="entityContactsCreateUpdateModalForm"
      @submit="submit"
    >
      <FormulateInput
        type="autocomplete"
        autoCompleteType="contact"
        name="contact"
        label="Contact"
        placeholder="Kies een contact"
        validation="required"
      />
      <FormulateInput
        type="select"
        name="role"
        label="Rol"
        :options="roles"
        placeholder="Kies een rol"
        validation="required"
      />
      <FormulateInput
        type="submit"
        :disabled="isLoading"
        title="Opslaan"
        outer-class="tw-my-4 tw-float-right"
      >
        <i
          :class="[
            'fas tw-mr-2',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
          ]"
        />
        Opslaan
      </FormulateInput>
    </FormulateForm>
  </BaseModal>
</template>

<script>
import { successModal } from '@/modalMessages'
import { getEntityRoles, createPropertyContact, updatePropertyContact } from '@/services/properties'
import { createProjectContact, updateProjectContact } from '@/services/projects'

export default {
  name: 'EntityContactsCreateUpdateModal',
  data () {
    return {
      entityContact: {},
      roles: [],
      mode: 'create',
      values: {
        contact: null,
        role: null
      }
    }
  },
  created () {
    this.fetchEntityRoles()
  },
  methods: {
    show (entityContact, mode) {
      this.entityContact = entityContact
      this.mode = mode
      if (entityContact?.id) {
        this.values = {
          contact: entityContact.contact,
          role: entityContact.role.id
        }
      }
      this.$refs.modal.show()
    },
    hide () {
      this.entityContact = {}
      this.values = {
        contact: null,
        role: null
      }
      this.$refs.modal.hide()
    },
    async fetchEntityRoles () {
      const repsonse = await getEntityRoles()
      this.roles = repsonse.data.results.map(role => ({
        value: role.id,
        label: role.name
      }))
    },
    async createEntityContact (data) {
      try {
        const payload = {
          contact: data.contact.id,
          role: data.role
        }
        const response = 'property' in this.entityContact
          ? await createPropertyContact(this.entityContact.property, payload)
          : await createProjectContact(this.entityContact.project, payload)
        this.hide()
        successModal('Het contact werd toegevoegd.')
        this.$emit('created')
        return response
      } catch (error) {
        this.$formulate.handle(error, 'entityContactsCreateUpdateModalForm')
      }
    },
    async updateEntityContact (data) {
      try {
        const payload = {
          contact: data.contact.id,
          role: data.role
        }
        const response = 'property' in this.entityContact
          ? await updatePropertyContact(this.entityContact.property, this.entityContact.id, payload)
          : await updateProjectContact(this.entityContact.project, this.entityContact.id, payload)
        this.hide()
        successModal('Het contact werd aangepast.')
        this.$emit('updated')
        return response
      } catch (error) {
        this.$formulate.handle(error, 'entityContactsCreateUpdateModalForm')
      }
    },
    async submit (data) {
      if (this.mode === 'create') {
        return this.createEntityContact(data)
      } else {
        return this.updateEntityContact(data)
      }
    }
  }
}
</script>
