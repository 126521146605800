<template>
  <div>
    <BaseModal ref="modal" title="Publiceren" @hide="destroySlider">
      <FormulateForm
        #default="{ isLoading }"
        v-model="values"
        name="publicerenForm"
        @submit="submit"
      >
        <h2 class="tw-mt-0">Checks</h2>
        <div v-for="check in checks" :key="check.key" class="tw-text-sm tw-mb-2">
          <i :class="[
            'fas tw-mr-2 tw-text-center tw-text-base',
            check.status
              ? 'fa-badge-check tw-text-primary'
              : check.blocking
                ? 'fa-times-circle tw-text-error'
                : 'fa-exclamation-circle tw-text-warning'
            ]"
          />
          {{ check.label }}
          <template v-if="check.details">
            <br>
            <span class="tw-ml-7 tw-italic tw-text-xs">{{ check.details }}</span>
          </template>
        </div>
        <div>
          <div class="tw-w-full">
            <h2>Belangrijk voor facturatie</h2>
            <table class="tw-my-4 tw-w-full tw-table-auto">
              <tr>
                <th class="tw-w-1/2">Primair kantoor</th>
                <td class="tw-w-1/2">{{ primaryOffice.reference }} {{ primaryOffice.name }}</td>
              </tr>
            </table>
          </div>

          <div class="tw-w-full">
            <h2>Informatie</h2>
            <table class="tw-my-4 tw-w-full tw-table-auto">
              <tr>
                <th class="tw-w-1/2">Prijs</th>
                <td class="tw-w-1/2">{{ currency(values.price) }}</td>
              </tr>
              <tr>
                <th class="tw-w-1/2">Prijs zichtbaarheid</th>
                <td class="tw-w-1/2">{{ values.publish_price ? 'Publiceer prijs' : 'Publiceer prijs niet' }}</td>
              </tr>
              <tr>
                <th class="tw-w-1/2">Adres zichtbaarheid</th>
                <td class="tw-w-1/2">{{ ADDRESS_PUBLISH_OPTIONS[values.publish_address] }}</td>
              </tr>
              <tr>
                <th class="tw-w-1/2">Concept</th>
                <td class="tw-w-1/2">{{ concept.name }}</td>
              </tr>
            </table>
          </div>
        </div>

        <FormulateInput
          v-if="confirmEpc"
          type="checkbox"
          name="confirm_epc"
          label="Ik bevestig dat voor dit pand geen EPC van toepassing is"
          validation="required"
          :validation-messages="{
            required: 'Bevestig alstublieft'
          }"
          @input="changeEPCBlocker"
        />

        <div ref="slider" class="tw-my-4 keen-slider tw-space-x-2">
          <div
            v-for="picture in pictures"
            :key="picture.id"
            class="keen-slider__slide"
          >
            <img :src="picture.url" alt="picture" />
          </div>
        </div>

        <div class="tw-flex tw-flex-col tw-items-end tw-w-full">
          <span v-if="disableSubmit" class="input-error">{{ disableSubmit }}</span>
          <FormulateErrors />
          <FormulateInput
            type="submit"
            title="Opslaan"
            :disabled="isLoading || disableSubmit"
            outer-class="tw-my-2"
          >
            <i
              :class="[
                'fas tw-mr-1',
                isLoading ? 'fa-spinner-third fa-spin' : 'fa-badge-check'
              ]"
            />
            Publiceren
          </FormulateInput>
        </div>
      </FormulateForm>
    </BaseModal>
    <BaseModal ref="portals" title="Portals" @hide="portalsHidden">
      <PortalPublications
        ref="portalPublications"
        :property="property"
        :marketing="marketing"
      />
      <FormulateInput
        type="button"
        outer-class="tw-mt-2 tw-float-right tw-mb-6"
        @click="openWizardEntityInvoice"
      >
        <i class="fas fa-arrow-right tw-mr-1" />
        Facturatie
      </FormulateInput>
    </BaseModal>
    <WizardEntityInvoice
      ref="wizardEntityInvoice"
      @wizardEntityInvoiceHidden="wizardEntityInvoiceHidden"
    />
  </div>
</template>

<script>
import KeenSlider from 'keen-slider'
import 'keen-slider/keen-slider.min.css'
import EventBus from '@/components/iam/bus'
import PortalPublications from '@/components/properties/PortalPublications'
import WizardEntityInvoice from '@/components/properties/WizardEntityInvoice'

import { currency } from '@/utils/helpers'
import { ADDRESS_PUBLISH_OPTIONS } from '@/forms/selectOptions'
import { createLog } from '@/services/apiService'
import {
  publishWizardComplete,
  createPropertyPortalPublication,
  getProperty,
  getPropertyMarketing,
  getPropertyPrePublishChecks,
  updateProperty,
  updatePropertyMarketing
} from '@/services/properties'

export default {
  name: 'WizardPubliceren',
  components: {
    PortalPublications,
    WizardEntityInvoice
  },
  props: {
    propertyId: {
      type: [Number, String],
      required: true
    },
    primaryOffice: {
      required: true
    }
  },
  constants: {
    ADDRESS_PUBLISH_OPTIONS
  },
  data () {
    return {
      marketing: null,
      checks: [],
      values: {},
      concept: {},
      pictures: [],
      slider: null,
      current: 0,
      property: null
    }
  },
  computed: {
    disableSubmit () {
      const blockers = this.checks.reduce((arr, check) => {
        if (check.blocking && !check.status) arr.push(check.label.toLowerCase())
        return arr
      }, [])
      return blockers.length
        ? 'Vereiste checks: ' + blockers.join(', ')
        : false
    },
    confirmEpc () {
      return this.checks.some(check => check.key === 'epc' && !check.status)
    }
  },
  methods: {
    currency,
    initiateSlider () {
      this.slider = new KeenSlider(this.$refs.slider, {
        initial: this.current,
        slidesPerView: 4,
        loop: this.pictures.length > 4,
        slideChanged: slide => {
          this.current = slide.details().relativeSlide
        }
      })
    },
    destroySlider () {
      if (this.slider) this.slider.destroy()
    },
    hide () {
      this.$refs.modal.hide()
      this.destroySlider()
      this.values = {}
    },
    openWizardEntityInvoice () {
      this.$refs.portals.hide()
      this.$refs.wizardEntityInvoice.init()
    },
    wizardEntityInvoiceHidden () {
      EventBus.$emit('initProperty')
    },
    async portalsHidden () {
      const response = await publishWizardComplete(this.propertyId)
      return response
    },
    changeEPCBlocker (value) {
      // As requested by Jan, if the checkbox is ticked, then the EPC check is non-blocking (blocking: false)
      const check = this.checks.find(check => check.key === 'epc')
      this.$set(check, 'blocking', !value)
    },
    async show () {
      await this.loadPrePublishChecks()
      setTimeout(() => {
        this.initiateSlider()
      }, 100)
      this.$refs.modal.show()
    },
    async loadPrePublishChecks () {
      const [marketing, prePublish] = await Promise.all([
        getPropertyMarketing(this.propertyId),
        getPropertyPrePublishChecks(this.propertyId)
      ])
      const { checks, pictures, concept, ...values } = prePublish.data
      this.checks = checks
      this.values = values
      this.concept = concept
      this.pictures = pictures
      this.marketing = marketing.data
      return [marketing, prePublish]
    },
    async submit ({ status_upon_publishing }) {
      try {
        // Update property status and marketing published status separately for the flow to work correctly
        const propertyUpdateResponse = await updateProperty(this.propertyId, { status: status_upon_publishing })
        await updatePropertyMarketing(this.propertyId, { published: true })
        await createLog({
          action_id: 25, // Publish action id
          content_object_id: this.propertyId,
          content_object_class: 'property'
        })
        const propertyResponse = await getProperty(this.propertyId)
        this.property = propertyResponse.data
        this.hide()
        // Portal 6 = Dewaele.com
        const portalPublicationCreateResponse = await createPropertyPortalPublication(this.propertyId, { portal: 6 })
        this.$refs.portals.show()
        return [propertyUpdateResponse, portalPublicationCreateResponse]
      } catch (error) {
        console.error(error)
        this.$formulate.handle(error, 'publicerenForm')
      }
    }
  }
}
</script>
