<template>
  <!-- Old map modal does not render the map if the Basemodal is shown. Nesting the map modal inside the BaseModal doesn't work either.
  After lot of debugging, came up with this solution: hide the wizard to display the map, then display wizard again.-->
  <BaseModal v-show="!mapVisible" ref="modal" title="Dossieropbouw" max-width="tw-max-w-4xl">
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      :key="formKey"
      name="dossieropbouwForm"
      invalid-message="Gelieve de verplichte velden correct in te vullen."
      @submit="submit"
    >
      <div class="tw-mb-8">
        <h2>Dossier</h2>
        <div class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
          <FormulateInput
            type="text"
            name="reference"
            label="Referentie"
            placeholder="Referentie"
            :validation="[
              ['bail'],
              ['required', 'trim'],
              ['validatePropertyReference', excludedPropertyReference]
            ]"
            outer-class="tw-m-0"
          />

          <FormulateInput
            type="select"
            name="transaction_type"
            label="Transactietype"
            :options="TRANSACTION_TYPES"
            :disabled="values.has_transaction"
            validation="required"
            outer-class="tw-m-0"
            @change="updateCommission"
          />

          <FormulateInput
            type="autocomplete"
            auto-complete-type="office"
            name="office"
            label="Kantoor"
            placeholder="Selecteer kantoor"
            validation="required"
            outer-class="tw-m-0"
            @input="updateOffice"
          />

          <FormulateInput
            type="autocomplete"
            auto-complete-type="collaborator"
            name="collaborator"
            label="Makelaar"
            placeholder="Selecteer makelaar"
            validation="required"
            outer-class="tw-m-0 tw-col-span-2"
            @input="updateCollaborator"
          />

          <FormulateInput
            id="concept_type"
            type="select"
            name="concept_type"
            label="Concept"
            :options="CONCEPT_OPTIONS"
            validation="required"
            outer-class="tw-m-0"
          />
        </div>
        <div>
          <FormulateInput
            id="allow_automated_communication"
            type="checkbox"
            name="allow_automated_communication"
            label="Automatische communicatie aan eigenaar toestaan"
          />
        </div>
      </div>

      <div class="tw-mb-8">
        <h2>Adres</h2>
        <div class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
          <FormulateInput
            type="autocomplete"
            auto-complete-type="street"
            name="street"
            label="Straat"
            placeholder="Zoek een straat"
            validation="required"
            outer-class="tw-m-0"
            @callback="streetSelected"
          />
          <div class="tw-grid md:tw-grid-cols-2 tw-gap-4">
            <FormulateInput
              type="text"
              name="number"
              label="Huisnummer"
              placeholder="Huisnummer"
              validation="required:trim"
              outer-class="tw-m-0"
            />
            <FormulateInput
              type="text"
              name="box"
              label="Bus"
              placeholder="Bus"
              outer-class="tw-m-0"
            />
          </div>
          <FormulateInput
            type="autocomplete"
            auto-complete-type="city"
            name="city"
            label="Plaats"
            placeholder="Zoek op postcode of plaatsnaam"
            validation="required"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="button"
            title="Geografisch"
            :input-class="['tw-bg-transparent tw-text-success tw-border tw-border-success tw-w-full hover:tw-bg-success hover:tw-text-white']"
            outer-class="tw-m-0"
            @click="showMapModal"
          >
            <i class="fa fa-globe" /> Geografisch
          </FormulateInput>
        </div>
      </div>

      <div class="tw-mb-8">
        <h2>Pandgegevens</h2>
        <div class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
          <FormulateInput
            type="select"
            name="type"
            label="Type"
            :options="PROPERTY_TYPES"
            validation="required"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="number"
            name="build_year"
            label="Bouwjaar"
            placeholder="Bouwjaar"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="number"
            name="renovation_year"
            label="Renovatiejaar"
            outer-class="tw-m-0"
            placeholder="Renovatiejaar"
          />
        </div>
      </div>

      <div class="tw-mb-8">
        <h2>Eigenaars</h2>
        <EntityContactsFormPartial
          ref="entityOwners"
          contact-role="owner"
          :form-values="values"
          :property-id="propertyId"
          :show-disclaimer="false"
        />
      </div>

      <div class="tw-my-8">
        <h2>Prijs</h2>
        <div class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
          <div v-if="isSales" class="tw-col-span-3 tw-grid md:tw-grid-cols-3 tw-gap-4">
            <FormulateInput
              v-model="isVatSystem"
              type="checkbox"
              name="is_vat_system"
              label="Btw-stelsel"
              outer-class="tw-m-0"
              @change="updateCommission"
            />
            <FormulateInput
              v-if="isVatSystem"
              type="radio"
              name="vat_rate"
              label="BTW-type:"
              value="21"
              :options="{ 6: '6%', 12: '12%', 21: '21%' }"
              :input-class="['tw-my-0']"
              :wrapper-class="['tw-flex tw-flex-row tw-gap-2 tw-items-center']"
              :element-class="['tw-flex tw-flex-row tw-gap-2 tw-items-center']"
              outer-class="tw-m-0"
            />
          </div>
          <FormulateInput
            v-show="splitPriceInputs"
            type="number"
            id="price_land_share"
            name="price_land_share"
            label="Prijs grondaandeel"
            placeholder="Prijs grondaandeel"
            :validation="splitPriceInputs ? 'bail|landShareOrInfrastructureIsRequired|number|min:0' : 'bail|number|min:0'"
            step=".01"
            min="0"
            lang="nl"
            outer-class="tw-m-0"
            :element-class="['tw-flex tw-items-center']"
            :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
            @change="updateCommission"
          >
            <div slot="suffix" class="input-unit">
              <i class="fas fa-euro-sign" />
            </div>
          </FormulateInput>
          <FormulateInput
            v-show="splitPriceInputs"
            type="number"
            id="price_infrastructure"
            name="price_infrastructure"
            label="Prijs constructiewaarde"
            placeholder="Prijs constructiewaarde"
            :validation="splitPriceInputs ? 'bail|landShareOrInfrastructureIsRequired|number|min:0' : 'bail|number|min:0'"
            step=".01"
            min="0"
            lang="nl"
            outer-class="tw-m-0"
            :element-class="['tw-flex tw-items-center']"
            :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
            @change="updateCommission"
          >
            <div slot="suffix" class="input-unit">
              <i class="fas fa-euro-sign" />
            </div>
          </FormulateInput>
          <FormulateInput
            v-show="!splitPriceInputs"
            id="price"
            type="number"
            name="price"
            :label="isSales ? 'Aankoopbedrag' : 'Huurprijs (maandhuur)'"
            :placeholder="isSales ? 'Aankoopbedrag' : 'Huurprijs (maandhuur)'"
            validation="bail|required|number|min:0"
            step=".01"
            min="0"
            lang="nl"
            outer-class="tw-m-0"
            :element-class="['tw-flex tw-items-center']"
            :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
            @change="updateCommission"
          >
            <div slot="suffix" class="input-unit">
              <i class="fas fa-euro-sign" />
            </div>
          </FormulateInput>
        </div>
      </div>

      <div class="tw-mb-8">
        <h2>Commissie</h2>
        <div class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
          <FormulateInput
            v-show="!isSales"
            type="select"
            name="commission_type_in"
            label="Vooropgestelde commissie"
            :options="[
              { value: 6, label: 'Één maand huur' },
              { value: 17, label: '5% op jaarhuur' },
              { value: 15, label: '10% op jaarhuur' },
              { value: 9, label: '15% op jaarhuur' },
              { value: 12, label: 'Andere' }
            ]"
            validation="bail|required|number"
            :disabled="values.has_transaction"
            outer-class="tw-m-0"
            @change="updateCommission"
          />
          <FormulateInput
            v-show="isSales"
            type="radio"
            name="commission_type"
            :options="{
              percentage: 'Percentage',
              fix: 'Fix'
            }"
            :input-class="['tw-my-4']"
            outer-class="tw-m-0"
            @input="handleCommissionTypeChange"
          />
          <div class="tw-grid md:tw-grid-cols-1">
            <FormulateInput
              v-show="isSales"
              id="commission_percentage_in"
              type="number"
              name="commission_percentage_in"
              placeholder="Percentage"
              validation="bail|required|number|min:0"
              step=".01"
              min="0"
              lang="nl"
              :disabled="!disableCommissionField"
              outer-class="tw-m-0"
              :element-class="['tw-flex tw-items-center']"
              :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
              @input="updateCommission"
            >
              <div slot="suffix" class="input-unit">
                <i class="fas fa-percent" />
              </div>
            </FormulateInput>
            <FormulateInput
              v-model="commissionFixIn"
              id="commission_fix_in"
              type="number"
              name="commission_fix_in"
              :label="isSales ? '' : 'Commissiewaarde'"
              :placeholder="isSales ? 'Fix' : 'Commissiewaarde'"
              validation="bail|required|number|min:0"
              step=".01"
              min="0"
              lang="nl"
              :disabled="disableCommissionField"
              outer-class="tw-m-0"
              :element-class="['tw-flex tw-items-center']"
              :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
            >
              <div slot="suffix" class="input-unit">
                <i class="fas fa-euro-sign" />
              </div>
            </FormulateInput>
          </div>

        </div>
        <div v-if="values.commission_fix_in && isSales" class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
          <p><b>Vooropgestelde commissie:</b> {{ currency(values.commission_fix_in) }}</p>
        </div>
      </div>

      <div v-if="values.should_create_transaction && !production" class="tw-mb-8">
        <h2 class="tw-mb-4">Betrokken kantoren en poules</h2>
        <TransactionInvolvementData
          ref="transactionInvolvements"
          context="dossierPreparation"
          :property-id="propertyId"
        />
      </div>

      <div>
        <h2>Mandaat</h2>
        <div class="tw-my-4 tw-grid md:tw-grid-cols-1 tw-gap-x-4">
          <FormulateInput
            type="radio"
            name="mandate_type"
            :options="{
              exclusive: 'Exclusieve opdracht',
              co_mandate: 'Co-mandaat',
              open_mandate: 'Open mandaat'
            }"
            :input-class="['tw-my-1']"
            outer-class="tw-m-0 tw-mb-4"
            @input="handleMandateTypeChange"
          />
          <div v-if="values.mandate_type === 'co_mandate'">
            <fieldset class="tw-px-4 tw-border-gray-cc tw-rounded">
              <legend class="tw--mx-1 tw-my-0 tw-px-1 tw-w-auto tw-font-bold tw-text-sm tw-text-tg-color">Commissie co-mandaat</legend>
              <FormulateInput
                v-show="values.commission_type === 'percentage'"
                type="radio"
                name="co_mandate_percentage_type_in"
                :options="coMandatePercentageTypeOptions"
                :input-class="['tw-my-1']"
                outer-class="tw-m-0"
                @input="handleMandatePercentageTypeChange"
              />
              <div>
                <div v-show="values.co_mandate_percentage_type_in == '0'" class="tw-grid tw-grid-cols-2 tw-gap-x-4">
                  <FormulateInput
                    id="co_mandate_collaborator_percentage_in"
                    type="number"
                    name="co_mandate_collaborator_percentage_in"
                    label="Verkopende makelaar"
                    placeholder="Percentage"
                    :validation="values.co_mandate_percentage_type_in == '0' ? 'bail|required|number|min:0|validMandatePercentage' : ''"
                    :validation-rules="{ validMandatePercentage }"
                    :validation-messages="{
                      validMandatePercentage: 'De som van percentages moet 100% zijn'
                    }"
                    step=".01"
                    min="0"
                    lang="nl"
                    outer-class="tw-my-4"
                    :element-class="['tw-flex tw-items-center']"
                    :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
                  >
                    <div slot="suffix" class="input-unit">
                      <i class="fas fa-percent" />
                    </div>
                  </FormulateInput>
                  <FormulateInput
                    id="co_mandate_other_percentage_in"
                    type="number"
                    name="co_mandate_other_percentage_in"
                    label="Niet-verkopende makelaar"
                    placeholder="Percentage"
                    :validation="values.co_mandate_percentage_type_in == '0' ? 'bail|required|number|min:0|validMandatePercentage' : ''"
                    :validation-rules="{ validMandatePercentage }"
                    :validation-messages="{
                      validMandatePercentage: 'De som van percentages moet 100% zijn'
                    }"
                    step=".01"
                    min="0"
                    lang="nl"
                    outer-class="tw-my-4"
                    :element-class="['tw-flex tw-items-center']"
                    :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
                  >
                    <div slot="suffix" class="input-unit">
                      <i class="fas fa-percent" />
                    </div>
                  </FormulateInput>
                </div>
                <div v-show="values.co_mandate_percentage_type_in == '1'" class="tw-grid tw-grid-cols-2 tw-gap-x-4">
                  <FormulateInput
                    id="co_mandate_collaborator_percentage_in_commission_percentage"
                    type="number"
                    name="co_mandate_collaborator_percentage_in_commission_percentage"
                    label="Verkopende makelaar"
                    placeholder="Percentage"
                    :validation="values.co_mandate_percentage_type == '1' ? 'bail|required|number|min:0|validMandatePercentageCommissionPercentage' : ''"
                    :validation-rules="{ validMandatePercentageCommissionPercentage }"
                    :validation-messages="{
                      validMandatePercentageCommissionPercentage: `De som van percentages moet ${values.commission_percentage_in}% zijn`
                    }"
                    step=".01"
                    min="0"
                    lang="nl"
                    outer-class="tw-my-4"
                    :element-class="['tw-flex tw-items-center']"
                    :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
                  >
                    <div slot="suffix" class="input-unit">
                      <i class="fas fa-percent" />
                    </div>
                  </FormulateInput>
                  <FormulateInput
                    id="co_mandate_other_percentage_in_commission_percentage"
                    type="number"
                    name="co_mandate_other_percentage_in_commission_percentage"
                    label="Niet-verkopende makelaar"
                    placeholder="Percentage"
                    :validation="values.co_mandate_percentage_type_in == '1' ? 'bail|required|number|min:0|validMandatePercentageCommissionPercentage' : ''"
                    :validation-rules="{ validMandatePercentageCommissionPercentage }"
                    :validation-messages="{
                      validMandatePercentageCommissionPercentage: `De som van percentages moet ${values.commission_percentage_in}% zijn`
                    }"
                    step=".01"
                    min="0"
                    lang="nl"
                    outer-class="tw-my-4"
                    :element-class="['tw-flex tw-items-center']"
                    :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
                  >
                    <div slot="suffix" class="input-unit">
                      <i class="fas fa-percent" />
                    </div>
                  </FormulateInput>
                </div>
              </div>
              <p v-if="coMandateCommissions">
                Commissie verkopende makelaar: {{ currency(coMandateCommissions.co_mandate_collaborator_commission)}}
                <br>
                Commissie niet-verkopende makelaar: {{ currency(coMandateCommissions.co_mandate_other_commission)}}
              </p>
            </fieldset>

          </div>
        </div>
        <div class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
          <FormulateInput
            type="date"
            name="mandate_signature_date"
            label="Datum ondertekening opdracht"
            placeholder="YYYY-MM-DD"
            validation="bail|required|date:YYYY-MM-DD"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="date"
            name="mandate_start_date"
            label="Startdatum opdracht"
            placeholder="YYYY-MM-DD"
            validation="bail|required|date:YYYY-MM-DD"
            :disabled="values.has_transaction"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="date"
            name="mandate_end_date"
            label="Einddatum opdracht"
            placeholder="YYYY-MM-DD"
            validation="bail|optional|date:YYYY-MM-DD"
            outer-class="tw-m-0"
          />

          <FormulateInput
            type="file"
            name="mandate_file"
            label="Opdrachtdocument"
            :uploader="uploadFile"
            outer-class="formulate-input tw-my-0"
            wrapper-class="formulate-input-wrapper"
            :upload-area-mask-class="['tw-h-10 tw-mt-1']"
            :file-class="['tw-h-10 !tw-mt-1']"
            element-class="formulate-input-element formulate-input-element--file"
          >
            <template #file="context">
              <BaseFileUploadDisplay v-bind="context" />
            </template>
          </FormulateInput>
        </div>
      </div>
      <div class="tw-flex tw-flex-wrap tw-w-full tw-justify-between tw-items-start">
        <ul class="formulate-help">
          <p class="tw-text-sm tw-font-semibold tw-mb-2">
            Volgende acties zullen worden uitgevoerd na opslaan:
          </p>
          <li v-if="values.lead_to_set_as_deal">
            • Volgende intakelead wordt als deal aangeduid:
            <router-link :to="{ name: 'LeadDetails',  params: { id: values.lead_to_set_as_deal.id }}" target="_blank">
              {{ values.lead_to_set_as_deal.candidate.display_name }}
            </router-link>
          </li>
          <li>• Status van het pand wordt <b>Dossieropbouw</b></li>
          <li>• Checklist <b>Opstart verkoop/verhuur/overname</b> wordt geladen</li>
          <li v-if="values.should_create_transaction">• Een transactie <b>binnen</b> wordt gecreëerd</li>
          <li v-if="values.should_create_transaction">• De transactie-unit wordt bepaald uit bovenstaande parameters</li>
          <li v-show="showEmailMessage">• De eigenaars worden <b>per e-mail</b> op de hoogte gebracht van de start van het dossier</li>
        </ul>
        <div class="tw-text-right">
          <FormulateErrors />
          <FormulateInput
            type="submit"
            title="Opslaan"
            :disabled="isLoading"
            outer-class="tw-my-2"
          >
            <i
              :class="[
                'fas tw-mr-1',
                isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
              ]"
            />
            Opslaan
          </FormulateInput>
        </div>
      </div>
    </FormulateForm>
  </BaseModal>
</template>

<script>
import { currency, percentage, today } from '@/utils/helpers'
import EventBus from '@/components/iam/bus'
import { questionModal } from '@/modalMessages'

import EntityContactsFormPartial from '@/components/properties/EntityContactsFormPartial'
import TransactionInvolvementData from '@/components/transactions/TransactionInvolvementData'

import { CONCEPT_OPTIONS } from '@/forms/selectOptions'
import { PROPERTY_TYPES } from '@/forms/schemas/portfolio/property/address'

import { markDeal } from '@/services/sales'
import { createTransaction } from '@/services/transactions'
import {
  createDefaultPropertyFolderStructure,
  createPropertyFile,
  getPropertyDossierData,
  postPropertyDossierPreparation,
  updateProperty,
  updatePropertyCollaborators,
  updatePropertyConcept,
  updatePropertyFinancial,
  updatePropertyOffices,
  updatePropertyTechnical,
  uploadFile
} from '@/services/properties'

const FILE_TYPES = {
  2: 53, // If transaction_type is 'Te koop'
  3: 52, // If transaction_type is 'Te huur'
  4: 76 // If transaction_type is 'Over te nemen'
}

export default {
  name: 'WizardDossieropbouw',
  components: {
    EntityContactsFormPartial,
    TransactionInvolvementData
  },
  props: {
    property: {
      type: Object,
      required: true
    },
    mapVisible: {
      type: Boolean,
      required: true
    }
  },
  constants: {
    PROPERTY_TYPES,
    CONCEPT_OPTIONS,
    TRANSACTION_TYPES: {
      2: 'Te koop',
      3: 'Te huur',
      4: 'Over te nemen'
    }
  },
  data () {
    return {
      values: {},
      formKey: 'init',
      isVatSystem: false,
      transactionType: null,
      excludedPropertyReference: null, // We store the property's existing reference for the valid reference check
      currentOffice: null,
      currentCollaborator: null,
      defaultFolderStructureCreated: false,
      commissionFixIn: null
    }
  },
  computed: {
    production () {
      return process.env.VUE_APP_ENVIRONMENT === 'prod'
    },
    propertyId () {
      return this.property.id
    },
    isSales () {
      return [2, 4].includes(parseInt(this.values.transaction_type))
    },
    isDIY () {
      return parseInt(this.values.concept_type) === 6
    },
    splitPriceInputs () {
      return this.isSales && this.isVatSystem
    },
    disableCommissionField () {
      if (this.values.has_transaction) return true // Disabled
      if (this.isSales && this.values.commission_type === 'percentage') return true // Disabled
      return this.isSales ? false : this.values.commission_type_in !== '12' // Value received from form is string
    },
    showEmailMessage () {
      if (this.isDIY) return false
      return this.values.allow_automated_communication
    },
    coMandatePercentageTypeOptions () {
      if (this.values.commission_type === 'fix') {
        return { 0: 'Procentuele verdeling afgesproken ereloon bij opdracht' }
      } else {
        return {
          0: 'Procentuele verdeling afgesproken ereloon bij opdracht',
          1: 'Procentuele verdeling commissiepercentage'
        }
      }
    },
    price () {
      const value = this.splitPriceInputs
        ? parseFloat(this.values.price_infrastructure || 0) + parseFloat(this.values.price_land_share || 0)
        : parseFloat(this.values.price || 0)
      return value
    },
    coMandateCommissionsCommissionPercentageSplit () {
      return {
        co_mandate_collaborator_commission: (this.price * (this.values.co_mandate_collaborator_percentage_in_commission_percentage / 100)).toFixed(2),
        co_mandate_other_commission: (this.price * (this.values.co_mandate_other_percentage_in_commission_percentage / 100)).toFixed(2)
      }
    },
    coMandateCommissionsFixSplit () {
      return {
        co_mandate_collaborator_commission: (this.values.commission_fix_in * (this.values.co_mandate_collaborator_percentage_in / 100)).toFixed(2),
        co_mandate_other_commission: (this.values.commission_fix_in * (this.values.co_mandate_other_percentage_in / 100)).toFixed(2)
      }
    },
    coMandateCommissions () {
      if (this.values.co_mandate_percentage_type_in === '0' || this.values.commission_type === 'fix') return this.coMandateCommissionsFixSplit
      if (this.values.co_mandate_percentage_type_in === '1') return this.coMandateCommissionsCommissionPercentageSplit
      return null
    }
  },
  methods: {
    currency,
    percentage,
    async show () {
      const response = await this.loadDossierData(this.propertyId)
      this.$refs.modal.show()
      return response
    },
    async showMapModal () {
      const response = await this.updateAddress(this.values)
      this.$emit('showMapModal')
      return response
    },
    hide () {
      this.$refs.modal.hide()
    },
    streetSelected (street) {
      if (street?.city) this.values.city = street.city
      if (street?.number) this.values.number = street.number
    },
    parseFileObj (file) {
      if (!file) return false
      // Formulate input requires filename and url
      const { id, filename, url } = file
      return [{ id, name: filename, url }]
    },
    updateCommission () {
      return this.isSales ? this.updateSalesCommission() : this.updateRentalCommission()
    },
    updateSalesCommission () {
      const {
        commission_percentage_in,
        commission_type,
        commission_fix_in
      } = this.values
      if (commission_type === 'percentage') {
        this.commissionFixIn = ((commission_percentage_in / 100) * this.price).toFixed(2)
      } else {
        this.commissionFixIn = commission_fix_in
      }
    },
    updateRentalCommission () {
      const { price, commission_type_in, commission_fix_in } = this.values
      const rentalPrice = parseFloat(price)
      if (!commission_type_in) return false

      const commissionFixInValueMap = {
        6: rentalPrice, // Maandhuur = copy of the field Huurprijs
        16: rentalPrice / 2,
        17: 0,
        15: 0,
        9: 0,
        12: commission_fix_in // 0 and editable for the option "Andere"
      }
      const commissionFixValueIn = commissionFixInValueMap[parseInt(commission_type_in)]

      const commissionPercentageInMap = {
        6: 0,
        16: 0,
        17: 5,
        15: 10,
        9: 15,
        12: 0
      }
      const commissionPercentageIn = commissionPercentageInMap[parseInt(commission_type_in)]
      this.values.commission_percentage_in = commissionPercentageIn
      if (commissionFixValueIn) {
        this.values.commission_type = 'fix'
        this.commissionFixIn = commissionFixValueIn
      } else if (commissionPercentageIn) {
        this.values.commission_type = 'percentage'
        this.commissionFixIn = (rentalPrice * 12 * (commissionPercentageIn / 100)).toFixed(2)
      }
    },
    setDefaultCoMandatePercentageTypeIn () {
      if (this.values.mandate_type === 'co_mandate' && this.values.commission_type === 'fix') {
        this.$set(this.values, 'co_mandate_percentage_type_in', '0')
        this.handleMandatePercentageTypeChange()
      }
    },
    handleCommissionTypeChange () {
      this.updateCommission()
      this.setDefaultCoMandatePercentageTypeIn()
    },
    handleMandateTypeChange () {
      this.setDefaultCoMandatePercentageTypeIn()
      this.values.co_mandate_collaborator_percentage_in = null
      this.values.co_mandate_other_percentage_in = null
    },
    handleMandatePercentageTypeChange () {
      this.$set(this.values, 'co_mandate_collaborator_percentage_in', null)
      this.$set(this.values, 'co_mandate_other_percentage_in', null)
      this.$set(this.values, 'co_mandate_collaborator_percentage_in_commission_percentage', null)
      this.$set(this.values, 'co_mandate_other_percentage_in_commission_percentage', null)
    },
    validMandatePercentage ({ getFormValues }) {
      const { co_mandate_collaborator_percentage_in, co_mandate_other_percentage_in } = getFormValues()

      if (!co_mandate_collaborator_percentage_in || !co_mandate_other_percentage_in) return true
      const sum = parseInt(co_mandate_collaborator_percentage_in) + parseInt(co_mandate_other_percentage_in)
      return sum === 100
    },
    validMandatePercentageCommissionPercentage ({ getFormValues }) {
      const { co_mandate_collaborator_percentage_in_commission_percentage, co_mandate_other_percentage_in_commission_percentage } = getFormValues()

      if (!co_mandate_collaborator_percentage_in_commission_percentage || !co_mandate_other_percentage_in_commission_percentage) return true
      const sum = parseFloat(co_mandate_collaborator_percentage_in_commission_percentage) + parseFloat(co_mandate_other_percentage_in_commission_percentage)
      return sum === parseFloat(this.values.commission_percentage_in)
    },
    async loadDossierData (propertyId) {
      this.formKey = Math.random()
      const response = await getPropertyDossierData(propertyId)
      const {
        reference,
        type,
        street,
        office,
        collaborator,
        mandate_file,
        mandate_start_date,
        transaction_type,
        commission_fix_in,
        ...values
      } = response.data
      const isSales = [2, 4].includes(parseInt(transaction_type))
      this.values = {
        office,
        reference,
        collaborator,
        type: type.id,
        street: { text_nl: street },
        mandate_file: this.parseFileObj(mandate_file),
        mandate_start_date: mandate_start_date || today.date,
        transaction_type: transaction_type || 2, // Te koop
        commission_type: isSales ? 'percentage' : 'fix',
        commission_percentage_in: transaction_type !== 3 ? 3.3 : 0,
        commission_fix_in: commission_fix_in || (values.price * 0.033).toFixed(2),
        mandate_type: 'exclusive',
        ...values
      }
      this.excludedPropertyReference = reference
      this.currentOffice = office?.id
      this.currentCollaborator = collaborator?.id
      this.commissionFixIn = commission_fix_in || (values.price * (this.values.commission_percentage_in / 100)).toFixed(2)
      return response
    },
    async createDefaultFolderStructure () {
      // Only create the folder structure if the transaction type is "for sale"
      if (!this.defaultFolderStructureCreated && this.values.transaction_type === 2) {
        const response = await createDefaultPropertyFolderStructure(this.propertyId)
        this.defaultFolderStructureCreated = true
        return response.data
      }
      return null
    },
    async uploadFile (file, progress) {
      try {
        progress(0)
        const formData = new FormData()
        formData.append('file', file)
        progress(25)
        const { key, filename } = (await uploadFile(this.propertyId, formData)).data
        progress(50)
        const folders = await this.createDefaultFolderStructure()
        // Look for a folder called "Verkoop- verhuuropdracht"
        const folder_id = folders?.find(folder => folder.name === 'Verkoop- verhuuropdracht')?.id
        const payload = { key, filename, file_type: FILE_TYPES[this.values.transaction_type], folder_id }
        const response = await createPropertyFile(this.propertyId, payload)
        progress(100)
        return this.parseFileObj(response.data)
      } catch (error) {
        this.$formulate.handle(error, 'dossieropbouwForm')
      }
    },
    async updateProperty ({
      reference,
      street,
      number,
      box,
      city,
      type,
      parcels,
      transaction_type,
      mandate_start_date,
      mandate_signature_date,
      allow_automated_communication
    }) {
      const payload = {
        reference,
        number,
        box,
        type,
        parcels,
        transaction_type,
        mandate_start_date,
        mandate_signature_date,
        city: city.id,
        street: street.text_nl,
        dossier_preparation_wizard: true,
        allow_automated_communication
      }
      const response = await updateProperty(this.propertyId, payload)
      return response.data
    },
    async updateAddress ({ street, number, box, city }) {
      const payload = {
        box,
        number,
        city: city.id,
        street: street.text_nl
      }
      const response = await updateProperty(this.propertyId, payload)
      return response
    },
    async updatePropertyFinancial ({ is_vat_system, price, price_land_share, price_infrastructure, transaction_type }) {
      const payload = { is_vat_system, price, price_land_share, price_infrastructure, transaction_type }
      const response = await updatePropertyFinancial(this.propertyId, payload)
      return response.data
    },
    async updatePropertyConcept ({ concept_type }) {
      const payload = { type: concept_type }
      const response = await updatePropertyConcept(this.propertyId, payload)
      return response.data
    },
    async updatePropertyTechnical ({ build_year, renovation_year }) {
      const payload = { build_year, renovation_year }
      const response = await updatePropertyTechnical(this.propertyId, payload)
      return response.data
    },
    async createTransaction ({
      has_transaction,
      commission_type,
      commission_percentage_in,
      commission_type_in,
      commission_fix_in,
      transaction_type,
      mandate_type,
      co_mandate_percentage_type_in,
      co_mandate_collaborator_percentage_in,
      co_mandate_collaborator_percentage_in_commission_percentage,
      co_mandate_other_percentage_in,
      co_mandate_other_percentage_in_commission_percentage,
      mandate_signature_date,
      concept_type
    }) {
      if (has_transaction) return false // We will not create a transaction if it already exists
      let commission_in = commission_fix_in
      let commission_external_amount_in = 0
      if (mandate_type === 'co_mandate') {
        commission_in = this.coMandateCommissions.co_mandate_collaborator_commission
        commission_external_amount_in = this.coMandateCommissions.co_mandate_other_commission
      }
      const payload = {
        property: this.propertyId,
        transaction_type,
        commission_type_in,
        commission_fix_value_in: commission_type === 'fix' ? commission_fix_in : 0,
        commission_percentage_in: commission_type === 'percentage' ? commission_percentage_in : 0,
        commission_fix_in: commission_in,
        commission_external_amount_in: commission_external_amount_in,
        mandate_type,
        co_mandate_percentage_type_in,
        co_mandate_collaborator_percentage_in: mandate_type === 'co_mandate' ? (co_mandate_collaborator_percentage_in || co_mandate_collaborator_percentage_in_commission_percentage) : null,
        co_mandate_other_percentage_in: mandate_type === 'co_mandate' ? (co_mandate_other_percentage_in || co_mandate_other_percentage_in_commission_percentage) : null,
        transaction_start_date: mandate_signature_date,
        concept_type,
        // Todo, change to false when referral flow goes live. For now: create transaction offices in production. Do not create them in QA, local
        should_create_transaction_offices: this.production
      }
      const response = await createTransaction(payload)
      return response
    },
    async markDeal ({ lead_to_set_as_deal }) {
      if (!lead_to_set_as_deal) return // No lead, no need
      const response = await markDeal(lead_to_set_as_deal.id)
      return response.data
    },
    async updateCollaborator (collaborator) {
      if (!collaborator?.id || this.currentCollaborator === collaborator.id) return false

      const response = await updatePropertyCollaborators(
        this.propertyId,
        {
          collaborator_id_list: [collaborator.id]
        }
      )
      this.currentCollaborator = collaborator.id
      return response
    },
    async updateOffice (office) {
      if (!office?.id || this.currentOffice === office.id) return false

      const response = await updatePropertyOffices(
        this.propertyId,
        {
          office_id_list: [office.id]
        }
      )
      this.currentOffice = office.id
      return response
    },
    async submitDossierPreparation () {
      const payload = {
        involvements: this.$refs.transactionInvolvements ? this.$refs.transactionInvolvements.getInvolvements() : []
      }
      const response = await postPropertyDossierPreparation(this.propertyId, payload)
      return response
    },
    async submit (data) {
      // First update the owners as this is an API call that is expected to trigger BE validations (duplicate contacts to be merged)
      if (!data.build_year) {
        const result = await questionModal(
          'Let op: bouwjaar is niet ingevuld! Dit is een belangrijk veld voor de opbouw van jouw dossier. Weet je zeker dat je wil verder gaan?', 'Bouwjaar'
        )
        if (!result.value) return
      }
      const owners = await this.$refs.entityOwners.submit()
      if (!owners) return // Stop form submission if the API call fails
      try {
        const [financial, concept] = await Promise.all([
          this.updatePropertyFinancial(data),
          this.updatePropertyConcept(data),
          this.updatePropertyTechnical(data),
          this.markDeal(data),
          this.createDefaultFolderStructure()
        ])
        // First update the property
        // Then create the transaction if needed
        // The transaction creation logic contains some logic to determine the unit type based on the property type
        // Which can be updated in this form
        const property = await this.updateProperty(data)
        if (this.values.should_create_transaction) await this.createTransaction(data)
        const dossierPreparationResponse = await this.submitDossierPreparation()
        this.hide()
        EventBus.$emit('initProperty')
        return { property, financial, concept, owners, dossierPreparationResponse }
      } catch (error) {
        console.error(error)
        this.$formulate.handle(error, 'dossieropbouwForm')
      }
    }
  }
}
</script>

<style scoped>
h2 {
  @apply tw-mt-0;
}
</style>
