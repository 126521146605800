<template>
  <div v-if="object" class="tw-flex tw-flex-wrap tw-gap-1">
    <span class="label">{{ object.created_on|date-day }} / {{ object.updated_on|date-day }}</span>
    <span class="label label-default hmodh-criteria" v-if="object.price_min || object.price_max">
      Prijs:
      <span v-if="object.price_min && !object.price_max">Min </span>
      <span v-if="object.price_min">{{ object.price_min|currency }}</span>
      <span v-if="object.price_max && object.price_min">-</span>
      <span v-if="object.price_max && !object.price_min">Max </span>
      <span v-if="object.price_max">{{ object.price_max|currency }}</span>
    </span>

    <span class="label label-default hmodh-criteria" v-if="object.bedrooms_min || object.bedrooms_max">
      Slaapkamers:
      <span v-if="object.bedrooms_min && !object.bedrooms_max">Min </span>
      <span v-if="object.bedrooms_min">{{ object.bedrooms_min }}</span>
      <span v-if="object.bedrooms_max && object.bedrooms_min">-</span>
      <span v-if="object.bedrooms_max && !object.bedrooms_min">Max </span>
      <span v-if="object.bedrooms_max">{{ object.bedrooms_max }}</span>
    </span>

    <span class="label label-default hmodh-criteria" v-if="object.restrooms_min || object.restrooms_max">
      Toiletten:
      <span v-if="object.restrooms_min && !object.restrooms_max">Min </span>
      <span v-if="object.restrooms_min">{{ object.restrooms_min }}</span>
      <span v-if="object.restrooms_max && object.restrooms_min">-</span>
      <span v-if="object.restrooms_max && !object.restrooms_min">Max </span>
      <span v-if="object.restrooms_max">{{ object.restrooms_max }}</span>
    </span>

    <span class="label label-default hmodh-criteria" v-if="object.bathrooms_min || object.bathrooms_max">
      Badkamers:
      <span v-if="object.bathrooms_min && !object.bathrooms_max">Min </span>
      <span v-if="object.bathrooms_min">{{ object.bathrooms_min }}</span>
      <span v-if="object.bathrooms_max && object.bathrooms_min">-</span>
      <span v-if="object.bathrooms_max && !object.bathrooms_min">Max </span>
      <span v-if="object.bathrooms_max">{{ object.bathrooms_max }}</span>
    </span>

    <span class="label label-default hmodh-criteria" v-if="object.build_year_from || object.build_year_till">
      Bouwjaar:
      <span v-if="object.build_year_from && !object.build_year_till">Vanaf </span>
      <span v-if="object.build_year_from">{{ object.build_year_from }}</span>
      <span v-if="object.build_year_till && object.build_year_from">-</span>
      <span v-if="object.build_year_till && !object.build_year_from">Tot </span>
      <span v-if="object.build_year_till">{{ object.build_year_till }}</span>
    </span>

    <span class="label label-default hmodh-criteria" v-if="object.surface_plot_min || object.surface_plot_max">
      Perceel opp:
      <span v-if="object.surface_plot_min && !object.surface_plot_max">Min </span>
      <span v-if="object.surface_plot_min">{{ object.surface_plot_min|floatformat(0) }}m²</span>
      <span v-if="object.surface_plot_max && object.surface_plot_min">-</span>
      <span v-if="object.surface_plot_max && !object.surface_plot_min">Max </span>
      <span v-if="object.surface_plot_max">{{ object.surface_plot_max|floatformat(0) }}m²</span>
    </span>

    <span class="label label-default hmodh-criteria" v-if="object.surface_trading_min || object.surface_trading_max">
      Handels opp:
      <span v-if="object.surface_trading_min && !object.surface_trading_max">Min - </span>
      <span v-if="object.surface_trading_min">{{ object.surface_trading_min|floatformat(0) }}m²</span>
      <span v-if="object.surface_trading_max && object.surface_trading_min">-</span>
      <span v-if="object.surface_trading_max && !object.surface_trading_min">Max - </span>
      <span v-if="object.surface_trading_max">{{ object.surface_trading_max|floatformat(0) }}m²</span>
    </span>

    <span class="label label-default hmodh-criteria" v-if="object.surface_livable_min || object.surface_livable_max">
      Bewoonbare opp:
      <span v-if="object.surface_livable_min && !object.surface_livable_max">Min - </span>
      <span v-if="object.surface_livable_min">{{ object.surface_livable_min|floatformat(0) }}m²</span>
      <span v-if="object.surface_livable_max && object.surface_livable_min">-</span>
      <span v-if="object.surface_livable_max && !object.surface_livable_min">Max - </span>
      <span v-if="object.surface_livable_max">{{ object.surface_livable_max|floatformat(0) }}m²</span>
    </span>
    <span v-if="object.manual" class="label label-default hmodh-criteria">Manueel</span>
    <span class="label label-default hmodh-criteria" v-if="object.garden">Tuin: <span
      v-if="object.garden === 1">Ja</span> <span
      v-if="object.garden === 2">Nee</span></span>
    <span class="label label-default hmodh-criteria" v-if="object.garage">Garage: <span
      v-if="object.garage === 1">Ja</span> <span v-if="object.garage === 2">Nee</span></span>
    <span class="label label-default hmodh-criteria" v-if="object.terrace">Terras: <span
      v-if="object.terrace === 1">Ja</span> <span v-if="object.terrace === 2">Nee</span></span>
    <span class="label label-default hmodh-criteria" v-if="object.bike_storage">Fietsenberging: <span
      v-if="object.bike_storage === 1">Ja</span> <span v-if="object.bike_storage === 2">Nee</span></span>
    <span class="label label-default hmodh-criteria" v-if="object.furnished">Bemeubeld: <span
      v-if="object.furnished === 1">Ja</span> <span v-if="object.furnished === 2">Nee</span></span>
    <span class="label label-default hmodh-criteria" v-if="object.swimmingpool">Zwembad: <span
      v-if="object.swimmingpool === 1">Ja</span> <span v-if="object.swimmingpool === 2">Nee</span></span>
    <span class="label label-default hmodh-criteria" v-if="object.is_newly_built">Nieuwbouw: <span
      v-if="object.is_newly_built === 1">Ja</span> <span v-if="object.is_newly_built === 2">Nee</span></span>
    <span class="label label-default hmodh-criteria" v-if="object.ready_to_go">Instapklaar:
      <span v-if="object.ready_to_go===1">Ja</span>
      <span v-if="object.ready_to_go===2">Nee</span>
    </span>
    <span class="label label-default hmodh-criteria" v-if="object.on_ground_floor">Gelijkvloers:
      <span v-if="object.on_ground_floor===1">Ja</span>
      <span v-if="object.on_ground_floor===2">Nee</span>
    </span>
    <span class="label label-default hmodh-criteria"
          v-if="object.zipcodes_count">Postcodes: {{ object.zipcodes_count}}</span>
    <span class="label label-default hmodh-criteria" v-if="object.geo_search && object.geo_search.length > 0">
      Regio op kaart
    </span>

    <template v-if="object.building_types">
      <span class="label label-default hmodh-criteria"
            v-if="object.building_types && object.building_types.length > 0 && object.building_types.includes(1)"> Type Bebouwing: Open </span>
      <span class="label label-default hmodh-criteria"
            v-if="object.building_types && object.building_types.length > 0 && object.building_types.includes(2)"> Type Bebouwing: Halfopen </span>
      <span class="label label-default hmodh-criteria"
            v-if="object.building_types && object.building_types.length > 0 && object.building_types.includes(3)"> Type Bebouwing: Gesloten </span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'HmodhCriteria',
  props: ['value', 'object']
}
</script>

<style scoped>
.label {
  margin: 0px 0px 1px 0px !important;
  display: inline-block;
}
</style>
